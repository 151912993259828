import { useState } from 'react'
import { Form } from "antd";
import { Button } from "antd/es";
import { FilterItem, Item, ItemChild } from '../entity';
import axios from 'axios';
import Groups from '../components/Groups';

interface Props {
  items: Item[]
  setFilterItems: (filtersItem: FilterItem[]) => void
  setCategories: (categories: ItemChild[]) => void
  toStep: (n : number) => void
}

export default function SelectCategoriesStep({ items, setFilterItems, setCategories } : Props) {

  const [form] = Form.useForm<Record<string, string[]>>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getFilter = () => {
    const data : ItemChild[] = []
    const values = form.getFieldsValue()
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const element = values[key];
        const _parent = items.find(x => x.name === key)
        if (!_parent) continue;
        if (!Array.isArray(element) || element.length === 0) continue;
        data.push(...element.map(x => _parent.children.find(y => y.link === x) as ItemChild))
      }
    }
    if (data.length === 0) return
    setIsLoading(true)
    setCategories(data)

    axios.post<FilterItem[]>('/get_filters',{
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data.map(x => x.link)
    }).then(r => {
      setFilterItems(r.data)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <Form form={form}>
        {items.map(x => <Form.Item name={x.name} key={x.name}>
          <Groups item={x} />
        </Form.Item>)}
      </Form>
      <Button className='mb-8 mt-2' type="primary" loading={isLoading} onClick={getFilter}>Next</Button>
    </>
  );
}