import { useState } from 'react'
import { Button, Form, Input, Popconfirm, Space } from "antd";
import { BrandItem, FilterItem, FilterItemChild, Item, ItemChild, TaskRequest } from '../entity';
import { socket } from '../function/socket';

interface Props {
  raw: Item[]
  categories: ItemChild[]
  filterItems: FilterItem[]
  brands: BrandItem[]
  toStep: (n : number) => void
  selectedFilterItems: FilterItemChild[]
}

export default function SettingProduct({raw, filterItems, categories, brands, toStep, selectedFilterItems } : Props) {

  const [form] = Form.useForm<Record<string, string>>()
  const formValues = Form.useWatch([], form)

  const [quick, setQuick] = useState<string>()

  const handleQuick = () => {
    if (!quick) return;
    categories.forEach(x => {
      const _count = brands.find(y => y.name === x.name)?.count ?? 0
      if (_count === 0) return
      form.setFieldValue(x.name, Math.min(Number(quick), _count).toString())
    })
  }

  const handleSubmit = () => {
    const values = form.getFieldsValue()
    const keys = Object.keys(values)
    const rawFilters = filterItems.filter(x => x.children.find(y => selectedFilterItems.find(z => z.id === y.id)))
    const filters : TaskRequest['filters'] = []
    rawFilters.forEach(x => {
      filters.push({
        name: x.name,
        slug: '',
      })
      x.children.forEach(y => {
        if (!selectedFilterItems.find(z => z.id === y.id)) return
        filters.push({
          name: y.name,
          slug: y.id,
          parent: x.name
        })
      })
    })
    const _data : TaskRequest['data'] = []
    keys.forEach(x => {
      const _value = values[x]
      const _brand = brands.find(y => y.name === x)
      if (!_brand || !_brand.count) return
      if (_value === '0') return
      const _parent = raw.find(y => y.children.find(z => z.name === x))
      if (!_parent) return
      _data.push({
        categories: [_parent.name, x],
        count: Number(_value ?? _brand.count),
        filters: filters.map(z => z.name),
        link: _brand.link
      })
      socket.emit('request', {
        filters,
        data: _data
      })

      toStep(3)
    })
  }

  return (
    <>
      {categories.length > 1 && <div className='border-blue-500 border-solid p-4 pb-0 mb-4 border' style={{ width: 500 }}>
        <Form.Item label="Quick fill:">
          <Space.Compact style={{ width: '100%' }}>
            <Input onChange={e => setQuick(e.target.value)} value={quick} type='number' min={0} />
            <Button type="primary" onClick={handleQuick}>Apply</Button>
          </Space.Compact>
        </Form.Item>
      </div>}
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 500 }}>
        {categories.map(x => {
          const _count = brands.find(y => y.name === x.name)?.count ?? 0
          return <Form.Item name={x.name} label={x.name} key={x.name}>
              <Input max={_count} min={0} disabled={_count === 0} type='number' addonAfter={`/${_count}`} />
            </Form.Item>
        })}
        <div>Total: {formValues ? Object.values(formValues).reduce((a,b) => (!b || b === '0') ? a : a + Number(b), 0) : 0}</div>
        <Button className='mb-8 mt-2 mr-4 bg-green-500' type="primary" onClick={() => toStep(1)}>Prev</Button>
        <Popconfirm
          title="Add proccess"
          description="Are you sure to download these products?"
          onConfirm={handleSubmit}
        >
          <Button className='mb-8 mt-2' type="primary">Next</Button>
        </Popconfirm>
      </Form>
    </>
  );
}