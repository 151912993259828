import { useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Modal, Popconfirm, Space, Steps, Switch, Typography, notification } from "antd";
import Layout, { Content } from "antd/es/layout/layout";
import { API_DOMAIN, BrandItem, FilterItem, FilterItemChild, Item, ItemChild } from './entity';
import axios from 'axios';
import SelectCategoriesStep from './components/SelectCategoriesStep';
import SelectFilters from './components/SelectFilters';
import SettingProduct from './components/SettingProduct';
import { socket } from './function/socket';
import Exporting from './components/Exporting';
import { ToolOutlined } from '@ant-design/icons'

axios.defaults.baseURL = API_DOMAIN

interface ServerSetting {
  key: string,
  useProxy: boolean
}

function App() {
  const [raw, setRaw] = useState<Item[]>([])
  const [api, context] = notification.useNotification()
  const [categories, setCategories] = useState<ItemChild[]>([])
  const [filterItems, setFilterItems] = useState<FilterItem[]>([])
  const [selectedFilterItems, setSelectedFilterItems] = useState<FilterItemChild[]>([])
  const [brands, setBrands] = useState<BrandItem[]>([])
  const [current, setCurrent] = useState<number>(0)
  const [isRenewing, setIsRenewing] = useState<boolean>(false)
  const [isModalOpening, setIsModalOpening] = useState<boolean>(false)
  const [apiSettingClient, setApiSettingClient] = useState<ServerSetting>({ key: '', useProxy: false })
  const [apiSettingServer, setApiSettingServer] = useState<ServerSetting>({ key: '', useProxy: false })

  const toFilterStep = (items: FilterItem[]) => {
    setFilterItems(items)
    setCurrent(1)
  }
  const toProductSetting = (items: BrandItem[]) => {
    setBrands(items)
    setCurrent(2)
  }
  const toStep = (n: number) => setCurrent(n)

  useEffect(() => {
    function onConnect(e: string){
      console.log(e)
    }
    socket.on('connected', onConnect)
    socket.on('getCategories', (r: Item[]) => {
      setIsRenewing(false)
      setRaw(r)
    })
    socket.on('renewCategories',(e: string) => {
      if (e === 'success'){
        socket.emit('getCategories')
      }else{
        api.error({
          message: 'Cannot renew categories. Please try again!',
        })
      }
    })
    socket.on('receiveApiSetting', (e: ServerSetting) => {
      setApiSettingServer(e)
      setApiSettingClient(e)
    })
    socket.on('log', (e) => console.log(e))

    // Request
    socket.emit('getCategories')
    socket.emit('requestApiSetting')
    return () => {
      socket.off('connected', onConnect)
    }
  },[api])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {context}
      <Layout>
        <Content className="p-4">
          <h2 className="mt-0 flex w-full justify-between items-center">
            Get Contents Tool
            <div className='flex'>
              <Button className='my-2 mr-3' type='primary' onClick={() => setIsModalOpening(true)} icon={<ToolOutlined />} />
              <Button className='my-2' type="primary" onClick={() => toStep(3)}>View Task</Button>
            </div>
          </h2>
          <Steps
            className='mb-5'
            current={current}
            items={[
              {
                title: 'Select categories',
              },{
                title: 'Select filters'
              },{
                title: 'Setting product'
              },{
                title: 'Export'
              }
            ]}
          />
          {!isRenewing && <>
          {current === 0 && <SelectCategoriesStep toStep={toStep} items={raw} setCategories={setCategories} setFilterItems={toFilterStep} />}
          {current === 1 && <SelectFilters setSelectedFilterItems={setSelectedFilterItems} categories={categories} filterItems={filterItems} toStep={toStep} toProductSetting={toProductSetting} />}
          {current === 2 && <SettingProduct selectedFilterItems={selectedFilterItems} raw={raw} filterItems={filterItems} categories={categories} brands={brands} toStep={toStep} />}
          {current === 3 && <Exporting toStep={toStep} categories={categories} />}
          </>}
        </Content>
      </Layout>
      <Modal title="Server config" footer={<></>} open={isModalOpening} onCancel={() => {
        setIsModalOpening(false)
        setApiSettingClient(apiSettingServer)
      }}>
          <Typography.Title level={5}>1/Renew Categories</Typography.Title>
          <Popconfirm
            title="Add proccess"
            description="Are you sure to renew all categories?"
            onConfirm={() => {
              setIsRenewing(true)
              socket.emit('renewCategories')
            }}
          >
            <Button loading={isRenewing} className='mb-2' type="primary">Start</Button>
          </Popconfirm>
          <Divider />
          <Typography.Title level={5}>2/Update API key</Typography.Title>
          <Form layout="inline">
            <Form.Item>
              <Switch onChange={e => setApiSettingClient({ key: apiSettingClient.key, useProxy: e })} checked={apiSettingClient.useProxy} checkedChildren="Enable" unCheckedChildren="Disable" />
            </Form.Item>
            <Form.Item label="API Key">
              <Input disabled={!apiSettingClient.useProxy} value={apiSettingClient.key} onChange={e => setApiSettingClient({ key: e.target.value, useProxy: apiSettingClient.useProxy })} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => socket.emit('updateApiSetting', apiSettingClient)}>Update</Button>
            </Form.Item>
          </Form>
      </Modal>
    </Space>
  );
}

export default App;
