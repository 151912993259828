import { useEffect, useState, useMemo } from 'react'
import { Button, Checkbox, Form, Switch, Typography } from "antd";
import { Item, ItemChild } from "../entity";
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import _ from 'lodash';

interface Props{
  item: Item
  value?: string[]
  onChange?: (value: string[]) => void
}

export default function Groups({item, value, onChange} : Props){

  const [enable, setEnable] = useState<boolean>(false)
  const groups = useMemo(() => {
    if (item.children.every(x => !x.item_group || x.item_group === '')) return []
    const _groups : { name: string, children: ItemChild[] }[] = []
    const _other : { name: string, children: ItemChild[] } = {
      name: 'Other',
      children: []
    }
    item.children.forEach(x => {
      if (!x.item_group) {
        _other.children.push(x)
        return
      }
      const _group = _groups.find(y => y.name === x.item_group)
      if (!_group){
        _groups.push({
          name: x.item_group,
          children: [x]
        })
      }else{
        _group.children.push(x)
      }
    })
    if (_other.children.length) _groups.push(_other)
    return _groups
  },[item])
  const handleChange = (checked: CheckboxValueType[], groupName?: string) => {
    if (!onChange) return;
    if (groups.length > 0){
      const _group = groups.find(x => x.name === groupName)
      if (!_group) return
      const _groupValue = _group.children.map(y => y.link)
      const _excludeValue = _.difference(value, _groupValue)
      _excludeValue.push(...checked.map(x => x.toString()))
      onChange(
        _excludeValue
      )
      return
    }
    onChange(
      checked.map(x => x.toString())
    )
  }

  const selectAll = () => {
    if (!onChange) return
    onChange(item.children.map(x => x.link))
  }

  const selectGroup = (name: string) => {
    if (!onChange) return
    const _group = groups.find(x => x.name === name)
    if (!_group) return
    handleChange(_group.children.map(x => x.link), name)
  }

  const deSelectAll = () => {
    if (!onChange) return
    onChange([])
  }

  const deSelectGroup = (name: string) => {
    if (!onChange) return
    handleChange([], name)
  }

  useEffect(() => {
    if (enable) return
    if (!onChange) return
    onChange([])
  },[enable])

  return (
    <div className='border-solid border-blue-300 cat_group'>
      <Form.Item label={item.name} className='px-4 mb-0 uppercase'>
        <Switch checked={enable} onChange={e => setEnable(e)} />
        {enable && <><Button type="primary" size="small" onClick={selectAll} className="ml-4">Select All</Button>
          <Button type="primary" size="small" onClick={deSelectAll} className="ml-4 bg-green-400">Deselect All</Button>
        </>}
      </Form.Item>
      <div className='flex flex-wrap'>
        {enable ? 
            groups.length > 0 ? <>
              {groups.map(x => <div key={x.name} className='border-solid border-blue-300 cat_group p-4 mx-3 mb-4'>
                <Typography.Title level={5} className='mt-0'>
                  {x.name}
                  <Button type="primary" size="small" onClick={() => selectGroup(x.name)} className="ml-4">Select All</Button>
                  <Button type="primary" size="small" onClick={() => deSelectGroup(x.name)} className="ml-4 bg-green-400">Deselect All</Button></Typography.Title>
                <Checkbox.Group
                  options={x.children.map(x => ({ label: x.name, value: x.link }))}
                  value={value}
                  onChange={e => handleChange(e, x.name)}
                />
              </div>)}
            </> :
            <Checkbox.Group
              options={item.children.map(x => ({ label: x.name, value: x.link }))}
              value={value}
              onChange={handleChange}
            />
          : <></>
        }
      </div>
    </div>
  )
}