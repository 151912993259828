import _ from 'lodash'
import { Button, List } from "antd";
import { useState, useEffect } from 'react'
import { ItemChild, TaskResponse } from "../entity";
import { socket } from "../function/socket";

interface Props {
  toStep: (n : number) => void
  categories: ItemChild[]
}

export default function Exporting({toStep, categories} : Props){

  const [items, setItems] = useState<TaskResponse[]>([])

  useEffect(() => {
    function onTaskUpdate(e: TaskResponse[]){
      setItems(e)
    }
    socket.on('taskUpdated', onTaskUpdate)
    return () => {
      socket.off('taskUpdated', onTaskUpdate)
    }
  },[])

  return (
    <>
      {items.length === 0 ? <p>All tasks completed!</p> : <List
        dataSource={items}
        itemLayout="vertical"
        renderItem={(item) => {
          const _total = item.data.reduce((old, current) => old + current.count, 0)
          return <List.Item title={`TASK ID: ${item.id}`} key={item.id}>
            <p>Progress: {item.downloaded + item.errors} / {_total}</p>
            <p>Success: {item.downloaded} - Errors: {item.errors}</p>
            <p>Categories: {item.data.reduce((o,c) => _.union(o, c.categories),[] as string[]).join(' - ')}</p>
            <p>Filters: {item.filters.map(x => x.name).join(' - ')}</p>
          </List.Item>
        }}
      />}
      <Button className='mb-8 mt-2 mr-4 bg-green-500' type="primary" onClick={() => toStep(categories.length > 0 ? 2 : 0)}>Prev</Button>
    </>
  )
}