import _ from 'lodash'
import { useState } from 'react'
import { Button, Card, Col, Form, Radio, Row } from "antd";
import { BrandItem, FilterItem, FilterItemChild, Item, ItemChild } from '../entity';
import axios from 'axios';

interface Props {
  categories: ItemChild[]
  filterItems: FilterItem[]
  toProductSetting: (items: BrandItem[]) => void
  toStep: (n : number) => void
  setSelectedFilterItems: (filters: FilterItemChild[]) => void
}

export default function SelectFilters({categories, filterItems, toStep, toProductSetting, setSelectedFilterItems} : Props) {

  const [form] = Form.useForm<Record<string, string>>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getProduct = () => {
    const _values = form.getFieldsValue()
    const _filters : FilterItemChild[] = []
    for (const key in _values) {
      if (Object.prototype.hasOwnProperty.call(_values, key)) {
        const _parent = filterItems.find(x => x.name === key)
        if (!_parent) continue;
        const element = _values[key];
        if (!element || element.length === 0) continue;
        const _target = _parent.children.find(x => x.name === element)
        if (_target) _filters.push(_target)
      }
    }
    let _catLink : string[] = categories.map(x => x.link)
    _filters.forEach(x => {
      if (_catLink.length === 0) return _catLink = x.children
      _catLink = _.intersection(_catLink, x.children)
    })
    const _cat = categories.filter(x => _catLink.indexOf(x.link) > -1)
    const _filterId = _filters.map(x => x.id)
    const _data = _cat.map(x => ({
      ...x,
      filters: [..._filterId]
    }))
    setIsLoading(true)
    setSelectedFilterItems(_filters)
    axios.post('/get_product_count',{
      headers: { 
        'Content-Type': 'application/json'
      },
      data: _data
    }).then(r => {
      toProductSetting(r.data)
    }).finally(() => setIsLoading(false))
  }

  return (
    <Form form={form}>
      <Row>
        {filterItems.map(x => <Col span={24} className='px-4 mb-8' key={x.name}>
          <Card title={
            <div className='flex w-full justify-between'>
              <span>{x.name}</span>
              <Button type="primary" onClick={() => form.setFieldValue(x.name, undefined)}>Deselect</Button>
            </div>
          }>
            <Form.Item name={x.name}>
              <Radio.Group
                options={x.children.map(x => ({ label: x.name, value: x.name }))}
              />
            </Form.Item>
          </Card>
        </Col>)}
      </Row>
      <Button className='mb-8 mt-2 mr-4 bg-green-500' type="primary" disabled={isLoading} onClick={() => toStep(0)}>Prev</Button>
      <Button className='mb-8 mt-2' type="primary" loading={isLoading} onClick={getProduct}>Next</Button>
    </Form>
  );
}