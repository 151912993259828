export interface ItemChild {
  id: number
  name: string
  link: string
  parent: string
  item_group?: string
}
export type Item = ItemChild & {
  children: ItemChild[]
}
export type FilterItemChild = Omit<ItemChild, 'id'> & {
  id: string
  children: string[]
}
export interface FilterItem{
  name: string
  children: FilterItemChild[]
}

export type BrandItem = ItemChild & {
  count?: number
}

export type TaskRequest = {
  filters: {
    name: string
    slug: string
    parent?: string
  }[]
  data: {
    categories: string[]
    count: number
    filters: string[]
    link: string
  }[]
}
export type TaskResponse = TaskRequest & {
  id: string
  downloaded: number
  errors: number
}

export const API_DOMAIN = process.env.NODE_ENV === 'production' ? 'https://tool.dnda.vn' : 'http://localhost:3001'